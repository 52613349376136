@use '../../../../wrollo-palette' as wrollo-palette;

.csv-btn button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: wrollo-palette.$primary-color;
  padding: 0.25em 1em;
  color: white;
  font-size: 14px;
  font-weight: 400;
  font-family: Roboto, sans-serif;
  height: 36px;
  margin: 14px 0;
  cursor: pointer;
  text-align: center;
  border-radius: 4px;
  border: none;
  letter-spacing: 1.25px;
}

.disabled {
  background-color: rgba(0, 0, 0, 0.12) !important;
  cursor: default !important;
}
