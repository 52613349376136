@use '@angular/material' as mat;

/*
$wrollo-palette: (
    50 : #e7e5ed,
    100 : #c2bfd3,
    200 : #9a94b5,
    300 : #716997,
    400 : #524881,
    500 : #34286b,
    600 : #2f2463,
    700 : #271e58,
    800 : #21184e,
    900 : #150f3c,
    A100 : #8577ff,
    A200 : #5844ff,
    A400 : #2a11ff,
    A700 : #1a00f6,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);
*/
$wrollo-palette: (
    50 : #e6e4f2,
    100 : #c1bbde,
    200 : #988dc9,
    300 : #6f5fb3,
    400 : #503da2,
    500 : #311b92,
    600 : #2c188a,
    700 : #25147f,
    800 : #1f1075,
    900 : #130863,
    A100 : #9e95ff,
    A200 : #6f62ff,
    A400 : #412fff,
    A700 : #2915ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$primary-color: mat.get-color-from-palette($wrollo-palette, 500);
$secondary-color: mat.get-color-from-palette($wrollo-palette, 50);
